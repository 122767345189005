<template>
  <div class="mt-2 w-100 d-flex justify-content-center">
    <b-card class="w-75">
      <ValidationObserver ref="validationObserver" v-slot="{ invalid, handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <!-- Model Name -->
          <b-form-group
            label="Model name *"
            label-for="v-model-name"
          >
            <validation-provider
              v-slot="{ errors }"
              vid="modelName"
              name="Model name"
              rules="required|min:2"
            >
              <b-form-input
                id="v-model-name"
                v-model="modelName"
                placeholder="Model name"
                :state="errors.length > 0 ? false:null"
                debounce="275"
                @update="onUpdateModelName"
                @focusout="validate"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Model Key -->
          <b-form-group
            label="Model key *"
            label-for="v-model-key"
          >
            <validation-provider
              v-slot="{ errors }"
              ref="vpModelKey"
              vid="modelKey"
              name="Model key"
              rules="required|alpha-num|min:2"
            >
              <b-form-input
                id="v-model-key"
                v-model="modelKey"
                placeholder="Model key"
                :formatter="modelKeyFormatter"
                debounce="275"
                minlength="2"
                maxlength="10"
                @update="validate"
                @focusout="validate"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Model Description -->
          <b-form-group
            label="Model description"
            label-for="v-model-description"
          >
            <b-form-textarea
              id="v-model-description"
              v-model="modelDescription"
              placeholder="Model description"
              rows="4"
            />
          </b-form-group>

          <!-- Model Scope -->
          <b-form-group
            label="Model scope"
            label-for="v-model-scope"
          >
            <b-form-textarea
              id="v-model-scope"
              v-model="modelScope"
              placeholder="Model scope"
              rows="4"
            />
          </b-form-group>

          <!-- Buttons -->
          <div class="d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              :disabled="loading"
              @click.prevent="onReset"
            >
              Reset
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid || loading"
            >
              <span v-if="loading">
                <b-spinner small type="grow" />
                Creating...
              </span>
              <span v-else>
                Create Model
              </span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import axiosIns from '@/libs/axios'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { alphaNum, required, length } from '@validations'

export default {
  name: 'CreateModel',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { router } = useRouter()
    const modelName = ref('')
    const modelKey = ref('')
    const vpModelKey = ref('')
    const modelDescription = ref('')
    const modelScope = ref('')
    const validationObserver = ref(null)
    const loading = ref(false)

    const validate = () => {
      validationObserver.value
        .validate()
        .then(isValid => {
          const params = {
            modelName: modelName.value,
            modelKey: modelKey.value,
          }
          axiosIns
            .get('/api/v2/models/validate', { params })
            .then(({ data }) => {
              validationObserver.value.setErrors(data.errors)
            })
            .catch(e => console.error(e))
        })
    }
    const onUpdateModelName = () => {
      if (vpModelKey.value.flags.pristine) {
        generateModelKey()
      }
      validate()
    }
    const onSubmit = () => {
      validationObserver.value
        .validate()
        .then(isValid => {
          if (isValid === true) {
            loading.value = true
            const payload = {
              modelName: modelName.value,
              modelKey: modelKey.value,
              modelDescription: modelDescription.value,
              modelScope: modelScope.value,
            }
            axiosIns
              .post('/api/v2/models', payload)
              .then(({ data }) => { openModel(data) })
              .catch(err => { console.error(err) })
              .finally(() => { loading.value = false })
          }
        })
    }
    const onReset = () => {
      modelName.value = ''
      modelKey.value = ''
      modelDescription.value = ''
      modelScope.value = ''
      validationObserver.value.reset()
    }

    const generateModelKey = () => {
      if (modelName.value === '') {
        modelKey.value = ''
      } else {
        const payload = { name: modelName.value }
        axiosIns
          .post('/api/v2/models/generateKey', payload)
          .then(({ data }) => { modelKey.value = data.modelKey })
          .catch(error => { console.error(error) })
      }
    }
    const modelKeyFormatter = value => value.toUpperCase().substring(0, 12)
    const openModel = modelId => {
      store
        .dispatch('model/openWorkspace', modelId)
        .then(() => {
          router.push({
            name: 'model_dashboard',
            params: {
              modelKey: store.state.model.key,
            },
          })
        })
    }

    return {
      validationObserver,
      alphaNum,
      required,
      length,
      loading,
      modelName,
      modelKey,
      vpModelKey,
      modelDescription,
      modelScope,
      validate,
      onUpdateModelName,
      onSubmit,
      onReset,
      generateModelKey,
      modelKeyFormatter,
    }
  },
}
</script>
